// @flow

import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type CommonProps = {|
  children: React.Node
|};

export type DDProps = {|
  ...CommonProps,
  danger?: boolean
|};

// -------------------------------------------------------------------------------------------------

function DL(props: CommonProps): React.Node {
  const { children } = props;
  return <dl className="ui-dl">{children}</dl>;
}

DL.dt = function dt(props: CommonProps): React.Node {
  return <dt>{props.children}</dt>;
};

DL.dd = function dd(props: DDProps): React.Node {
  return <dd className={props.danger ? 'danger' : ''}>{props.children}</dd>;
};

DL.dd.normal = function normal(props: CommonProps): React.Node {
  return <span className="ui-dl-dd-normal">{props.children}</span>;
};

export default DL;
