// @flow

import cn from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';

import type { Color } from '../../Button';

// -------------------------------------------------------------------------------------------------

export type SnackType = Color;

export type SnackbarProps = {|
  onRequestClose?: () => void,
  autoHideDuration?: number,
  message?: React.Node,
  color?: SnackType,
  open: boolean
|};

// -------------------------------------------------------------------------------------------------

export default class Snackbar extends React.PureComponent<SnackbarProps> {
  // // --------------------------------------------------------------------------------------------

  componentDidMount(): void {
    if (this.props.open && this.props.autoHideDuration) {
      setTimeout(() => {
        this.props.open && this.props.onRequestClose && this.props.onRequestClose();
      }, this.props.autoHideDuration);
    }
  }

  componentDidUpdate(): void {
    if (this.props.open && this.props.autoHideDuration) {
      setTimeout(() => {
        this.props.open && this.props.onRequestClose && this.props.onRequestClose();
      }, this.props.autoHideDuration);
    }
  }

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { open, color, message, onRequestClose } = this.props;
    const body = document.body;

    if (!body) {
      return null;
    }

    const className = cn('ui-snackbar', {
      [color || 'color']: color,
      show: open,
      hide: !open
    });

    return ReactDOM.createPortal(
      <div className={className}>
        <div className="ui-snackbar-content">
          <span>{message}</span>
          <i className="icon-close" onClick={onRequestClose} />
        </div>
      </div>,
      body
    );
  }
}
