// @flow

import * as React from 'react';

import Snackbar from './Snackbar';

import type { SnackType } from './Snackbar';

// -------------------------------------------------------------------------------------------------

export type Snack = {|
  message: React.Node,
  color?: SnackType
|};

type AppSnackbarState = {|
  snack: null | Snack
|};

const INSTANCE: {
  queue: Array<Snack>,
  setState: any // TODO: define setState type
} = {
  setState: null,
  state: null,
  queue: []
};

class AppSnackbar extends React.PureComponent<*, AppSnackbarState> {
  state = {
    snack: null
  };

  static AUTOHIDE = 4000;
  lastColor: ?SnackType = undefined;

  // // --------------------------------------------------------------------------------------------

  componentDidMount(): void {
    INSTANCE.setState = this.setState.bind(this);
    AppSnackbar.processQueue();
  }

  // // --------------------------------------------------------------------------------------------

  componentWillUnmount(): void {
    INSTANCE.setState = null;
  }

  // // --------------------------------------------------------------------------------------------

  static processQueue(): void {
    const update = INSTANCE.setState;
    if (update && INSTANCE.queue.length > 0) {
      update({
        snack: INSTANCE.queue.shift(),
        open: true
      });
    } else if (update) {
      update({ snack: null });
    }
  }

  // // --------------------------------------------------------------------------------------------

  static pushSnack(snack: Snack): void {
    if (INSTANCE.queue.indexOf(snack) === -1) {
      INSTANCE.queue.push(snack);
    }

    AppSnackbar.processQueue();
  }

  static success(message: React.Node): void {
    AppSnackbar.pushSnack({
      color: 'success',
      message
    });
  }

  static error(message: React.Node): void {
    AppSnackbar.pushSnack({
      color: 'danger',
      message
    });
  }

  // // --------------------------------------------------------------------------------------------

  handleExited = () => {
    AppSnackbar.processQueue();
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    // TODO: make it lightweight

    const { snack } = this.state;
    this.lastColor = (snack ? snack.color : this.lastColor) || undefined;

    return (
      <Snackbar
        autoHideDuration={AppSnackbar.AUTOHIDE}
        onRequestClose={this.handleExited}
        message={snack && snack.message}
        color={this.lastColor}
        open={!!snack}
      />
    );
  }
}

export default AppSnackbar;
