// @flow

import * as React from 'react';

import A from '../../A';
import AppContext from '../../../helpers/AppContext';

const notProduction = (process.env.REACT_APP_STAGE || '').toLowerCase() !== 'production';

export default function AppFooter(): React.Node {
  const version = token =>
    token && notProduction ? (
      <A to="/graphiql">{process.env.REACT_APP_VERSION}</A>
    ) : (
      process.env.REACT_APP_VERSION
    );

  const year = new Date().getFullYear();

  return (
    <AppContext>
      {({ userToken }) => (
        <span className="ui-block-appfooter">
          &copy;
          {` ${year} EngerauLabs v.`}
          {version(!!userToken)}
        </span>
      )}
    </AppContext>
  );
}
