// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';

import Logger from '../../../helpers/Logger';

// -------------------------------------------------------------------------------------------------

export type BodyPopupProps = {|
  customStyles?: { [string]: string },
  onRequestClose?: () => void,
  children: React.Node,
  sameWidth?: boolean,
  open: boolean,
  target: any
|};

// -------------------------------------------------------------------------------------------------

export default class BodyPopup extends React.PureComponent<BodyPopupProps> {
  // // --------------------------------------------------------------------------------------------

  wrapperRef: RefObject = React.createRef<RefObject>();

  // // --------------------------------------------------------------------------------------------

  didRender = (): void => {
    const target = this.props.target && this.props.target.current;
    const wrapper = this.wrapperRef.current;

    if (!wrapper || !target || typeof target.getBoundingClientRect !== 'function') {
      Logger.error('Error creating popup', {
        target: !!target,
        wrapper: !!wrapper
      });
      return;
    }
    const rect = target.getBoundingClientRect();

    const body = document.body;

    const scrollTop =
      window.pageYOffset ||
      // $FlowFixMe
      document.documentElement.scrollTop ||
      // $FlowFixMe
      body.scrollTop;

    if (body && wrapper.parentNode) {
      wrapper.parentNode.style.height = body.offsetHeight + 'px';
    }

    wrapper.style.position = 'absolute';
    wrapper.style.left = rect.left + 'px';
    wrapper.style.top = rect.top + rect.height + scrollTop + 'px';
    if (this.props.sameWidth) {
      wrapper.style.right = rect.right + 'px';
    }
    const styles = this.props.customStyles;
    if (styles) {
      Object.keys(styles).forEach(key => {
        wrapper.style[key] = styles[key];
      });
    }
  };

  handleClose = (event: SyntheticEvent<any>): void => {
    // event.preventDefault();
    if (this.wrapperRef.current && this.wrapperRef.current.contains(event.target)) {
      return;
    }
    this.props.onRequestClose && this.props.onRequestClose();
  };

  // // --------------------------------------------------------------------------------------------

  componentDidMount(): void {
    this.didRender();
  }

  componentDidUpdate(): void {
    this.didRender();
  }

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { open, children } = this.props;

    return (
      document.body &&
      ReactDOM.createPortal(
        <div
          style={{ display: open ? 'block' : 'none' }}
          onClick={this.handleClose}
          className="ui-body-popup"
        >
          <div className="ui-body-popup-content" ref={this.wrapperRef}>
            {open && children}
          </div>
        </div>,
        document.body
      )
    );
  }
}
