// @flow

import * as React from 'react';

import { containers, blocks } from '../UI';

const { FullscreenContainer } = containers;
const { BigLoading } = blocks;

export default class LoadingPage extends React.PureComponent<*> {
  render(): React.Node {
    return (
      <FullscreenContainer>
        <BigLoading />
      </FullscreenContainer>
    );
  }
}
