// @flow

import op from 'object-path';
import * as React from 'react';

import wrapComponent from './wrapComponent';
import validateForm from './validateFormHelper';
import validateField from './validateFieldHelper';

import type { FormInterface, OnChangeEvent, ValidationRules } from './index';

// -------------------------------------------------------------------------------------------------

const { Provider, Consumer } = React.createContext({
  onChange: (e: OnChangeEvent<any>) => undefined,
  validationRules: undefined,
  disabled: false,
  errors: null,
  data: {}
});

// -------------------------------------------------------------------------------------------------

export type FormProviderProps = {|
  validationRules?: ValidationRules<*>,
  defaultValue: { [string]: any },
  onSubmit?: any => void,
  children: React.Node,
  disabled?: boolean
|};

type FormProviderState = FormInterface<*>;

// -------------------------------------------------------------------------------------------------

export type FieldConsumerProps = {
  component: React.ComponentType<*>,
  name: string
};

function FieldConsumer(props: FieldConsumerProps): React.Node {
  const { name, component, ...more } = props;
  const UIComponent = wrapComponent(component);
  return (
    <Consumer>
      {({ data, onChange, errors }) => (
        <UIComponent
          // $FlowFixMe
          error={errors && errors[name]}
          onChange={onChange}
          {...more}
          value={typeof data[name] === 'undefined' ? null : data[name]}
          name={name}
        />
      )}
    </Consumer>
  );
}

// -------------------------------------------------------------------------------------------------

export default class FormProvider extends React.PureComponent<
  FormProviderProps,
  FormProviderState
> {
  static +Field = FieldConsumer;

  constructor(props: FormProviderProps): void {
    super(props);
    this.state = {
      onChange: this.handleChange.bind(this),
      validationRules: props.validationRules,
      data: props.defaultValue || {},
      disabled: !!props.disabled,
      errors: null
    };
  }

  // // --------------------------------------------------------------------------------------------

  handleChange = (e: OnChangeEvent<any>): void => {
    let a = e.name.replace(/\./g, '.nested.');
    const rules = op.get(this.props.validationRules, a, null);
    let finalName = e.name.substr(e.name.lastIndexOf('.') + 1);

    // $FlowFixMe;
    this.setState(state => {
      const err = rules && validateField(finalName, rules, e.value, this.state.data);

      let update = {
        data: { ...state.data },
        errors: { ...state.errors }
      };

      if (e.name.indexOf('.') > -1) {
        let k = '';
        let n = e.name.substr(0, e.name.lastIndexOf('.'));

        n.split('.').forEach(sub => {
          k += '.' + sub;
          op.set(update, 'data' + k, { ...op.get(update, 'data' + k, {}) });
          op.set(update, 'errors' + k, {
            ...op.get(update, 'errors' + k, {})
          });
        });
      }

      op.set(update, 'data.' + e.name, e.value);

      if (err) {
        op.set(update, 'errors.' + e.name, err);
      } else {
        op.del(update, 'errors.' + e.name);
      }
      return update;
    });
  };

  // // --------------------------------------------------------------------------------------------

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const { validationRules, onSubmit } = this.props;
    if (validationRules) {
      const errors = validateForm(this.props.validationRules, this.state.data);
      if (errors) {
        this.setState({ errors }, () => {
          const list = document.getElementsByClassName('is-invalid-row');
          if (list && list[0]) {
            // $FlowFixMe
            window && window.scrollTo(0, Math.max(0, list[0].offsetTop - 100));
          }
        });
        return;
      }
    }
    onSubmit && onSubmit(this.state.data);
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    return (
      <Provider value={this.state}>
        <form onSubmit={this.handleSubmit}>
          {this.props.children}
          <button
            style={{
              visibility: 'hidden',
              overflow: 'hidden',
              position: 'fixed',
              opacity: '0',
              left: -9999,
              height: 0,
              width: 0
            }}
            type="submit"
          />
        </form>
      </Provider>
    );
  }
}
