// @flow
import Logger from '../Logger.js';

const MAP: { [string]: any } = {};

// -------------------------------------------------------------------------------------------------

const get = (locale: string) => {
  function getTranslation(key: string): null | string | Object {
    try {
      return MAP[locale] && MAP[locale].hasOwnProperty(key) ? MAP[locale][key] : null;
    } catch (e) {
      Logger.error(`Translation GET failed for ${locale}:${key}`);
      return null;
    }
  }

  return getTranslation;
};

// -------------------------------------------------------------------------------------------------

export default function fileSourceFactory(
  locale: string
): Promise<{ ['get' | 'match']: (string) => null | string | Object }> {
  return new Promise(resolve => {
    if (
      process.env.NODE_ENV === 'production' &&
      locale === process.env.REACT_APP_DEFAULT_LANGUAGE
    ) {
      return Promise.resolve({});
    }

    if (locale === 'sk') {
      if (MAP.sk) {
        return resolve(MAP.sk);
      }
      return import(/* webpackChunkName: "locale-sk" */ '../../assets/I18n/sk.json').then(
        module => {
          resolve(module.default);
        }
      );
    }
    if (MAP.en) {
      return resolve(MAP.en);
    }
    return import(/* webpackChunkName: "locale-en" */ '../../assets/I18n/en.json').then(module => {
      resolve(module.default);
    });
  }).then(resource => {
    MAP[locale] = resource;
    return {
      get: get(locale),
      match: () => null
    };
  });
}
