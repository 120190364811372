// @flow

import * as React from 'react';
import { Provider as I18nProvider } from '@serus/i18n';

import LoginPage from './pages/Login';
import { hot } from 'react-hot-loader';
import LogoutPage from './pages/Logout';
import LoadingPage from './pages/Loading';
import Route from 'react-router-dom/Route';
import Router from 'react-router-dom/Router';
import Switch from 'react-router-dom/Switch';
import history from './helpers/routerHistory';
import Redirect from 'react-router-dom/Redirect';
import fileSource from './helpers/I18n/fileSource';
import getInitLocale from './helpers/I18n/getInitLocale';
import AppContext, { AppProvider } from './helpers/AppContext';

// -------------------------------------------------------------------------------------------------

// TODO: extract
function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift();
  }
  return '';
}

const COOKIE_NAME = process.env.REACT_APP_TOKEN_COOKIE_NAME || 'userToken';
const API = process.env.REACT_APP_SERVER || '';

const defaultAppState = {
  apiUrl: API.replace('http://localhost', 'http://' + document.location.hostname),
  userToken: getCookie(COOKIE_NAME)
};

// -------------------------------------------------------------------------------------------------

const LoggedInWrapper = React.lazy(() =>
  import(/* webpackChunkName: "logged-in-wrapper" */ './pages/_components/LoggedInWrapper')
);

// -------------------------------------------------------------------------------------------------

class App extends React.Component<*> {
  // // --------------------------------------------------------------------------------------------

  static renderApp = (routeProps: any): React.Node => {
    return (
      <AppContext>
        {({ userToken }) =>
          userToken ? (
            <React.Suspense fallback={<LoadingPage />}>
              <LoggedInWrapper {...routeProps} />
            </React.Suspense>
          ) : (
            <Redirect to="/login" />
          )
        }
      </AppContext>
    );
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const locale = getInitLocale();
    return (
      <I18nProvider locale={locale} source={fileSource}>
        <AppProvider defaultState={defaultAppState}>
          <Router history={history}>
            <Switch>
              <Route path="/loading" component={LoadingPage} exact />
              <Route path="/logout" component={LogoutPage} exact />
              <Route path="/login" component={LoginPage} exact />
              <Route render={App.renderApp} />
            </Switch>
          </Router>
        </AppProvider>
      </I18nProvider>
    );
  }
}

export default hot(module)(App);
