// @flow

import type { CalendarEvent } from './Calendar';

// -------------------------------------------------------------------------------------------------

const dateToNum = (date: Date): number => {
  let d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return Math.ceil(d / 3600 / 24 / 1000);
};

export { dateToNum };

// -------------------------------------------------------------------------------------------------

export type StyledTableCell = {
  mark: 'left' | 'right' | 'both' | '',
  color: string,
  id: string
};

export default function tableCellDecorator(
  events: Array<CalendarEvent>
): {
  [number]: StyledTableCell
} {
  const colors: {
    [number]: StyledTableCell
  } = {};

  events.forEach((event: CalendarEvent) => {
    if (!event.from) {
      return;
    }
    let days = [new Date(event.from), new Date(event.to || event.from)];
    let length = dateToNum(days[1]) - dateToNum(days[0]);

    for (let i = 0; i <= length; i++) {
      let index = new Date(event.from);
      index.setUTCDate(index.getUTCDate() + i);
      index.setUTCHours(0, 0, 0, 0);
      index = index / 3600 / 24 / 1000;

      let alreadyExists = colors[index];
      colors[index] = {
        mark:
          alreadyExists || length === 0 ? 'both' : i > 0 ? (i >= length ? 'right' : '') : 'left',
        color: event.color || 'secondary',
        id: event.id
      };
    }
  });

  return colors;
}
