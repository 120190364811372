// @flow

import cn from 'classnames';
import * as React from 'react';

import Button from '../Button';

// -------------------------------------------------------------------------------------------------

export type SubmitFieldProps = {|
  children: React.Node,
  disabled: boolean,
  lg?: boolean,
  sm?: boolean
|};

// -------------------------------------------------------------------------------------------------

export default function SubmitField(props: SubmitFieldProps): React.Node {
  const { sm, lg, children, disabled } = props;

  const className = cn('form-group', {
    'mt-2': !sm && !lg,
    'mt-4': !sm && lg,
    'mt-0': sm && !lg
  });

  return (
    <div className={className}>
      <Button color="success" block lg disabled={disabled} shadow>
        {children}
      </Button>
    </div>
  );
}
