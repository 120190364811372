// @flow

import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type CommonProps = {|
  children: React.Node,
  white?: boolean
|};

// -------------------------------------------------------------------------------------------------

export default function Common(props: CommonProps): React.Node {
  const { white } = props;
  return <div className={'ui-container-common' + (white ? ' white' : '')}>{props.children}</div>;
}
