// @flow

import * as React from 'react';

import blocks from '../../_blocks';
import BodyPopup from './BodyPopup';
import TextField from '../TextField';

import type { OnChangeEvent, FieldProps } from '../index';

const { Calendar } = blocks;

// -------------------------------------------------------------------------------------------------

export type DatePickerProps = {|
  ...FieldProps<Date>,
  shadow?: boolean,
  locale: string,
  open?: boolean
|};

type DatePickerState = {| open: boolean, month: Date, inputValue: string |};

// -------------------------------------------------------------------------------------------------

export default class DatePicker extends React.PureComponent<DatePickerProps, DatePickerState> {
  // // --------------------------------------------------------------------------------------------

  targetRef: null | React$ElementRef<*> = React.createRef();

  constructor(props: DatePickerProps): void {
    super(props);
    this.state = {
      month: props.value ? new Date(props.value) : new Date(),
      inputValue: this.formatString(props.value),
      open: !!props.open
    };
  }

  // // --------------------------------------------------------------------------------------------

  handleChange = (e: OnChangeEvent<string>): void => {
    this.setState({
      inputValue: e.value || ''
    });
  };

  // // --------------------------------------------------------------------------------------------

  openPopup = () => {
    this.setState(state =>
      state.open
        ? null
        : { open: true, month: this.props.value ? new Date(this.props.value) : new Date() }
    );
  };

  // // --------------------------------------------------------------------------------------------

  handlePick = (date: Date): void => {
    this.setState(
      state => (state.open ? { open: false, inputValue: this.formatString(date) } : null),
      _ => {
        this.props.onChange({ name: this.props.name, value: date });
      }
    );
  };

  // // --------------------------------------------------------------------------------------------

  closePopup = () => {
    this.setState(state => (state.open ? { open: false } : null));
  };

  // // --------------------------------------------------------------------------------------------

  handleSwitchMonth = (month: Date): void => {
    this.setState(state => ({ month }));
  };

  // // --------------------------------------------------------------------------------------------

  formatString = (date: ?Date) => {
    const { locale } = this.props;
    const format = new Intl.DateTimeFormat(locale, {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit'
    });
    return date instanceof Date ? format.format(date) : '';
  };

  // // --------------------------------------------------------------------------------------------

  formatLocalValue = (): void => {
    let value = this.state.inputValue
      .replace(/[. ]+/g, '-')
      .split('-')
      .map(i => parseInt(i, 10) || 0)
      .filter(i => i);

    if (value.length !== 3 || value[0] > 31 || value[1] > 12) {
      this.setState({ inputValue: this.formatString(null) });
      return;
    }

    let date = new Date();
    date.setFullYear((value[2] < 100 ? 2000 : 0) + value[2]);
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(value[0]);
    date.setUTCMonth(value[1] - 1);

    if (value !== this.props.value) {
      this.setState({ inputValue: this.formatString(date) }, _ => {
        this.props.onChange({ name: this.props.name, value: date });
      });
    }
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { value, locale, ...more } = this.props;
    const { open, month, inputValue } = this.state;

    const domNode: null | HTMLElement = (this.targetRef && this.targetRef.current) || null;

    let inputRect: null | { left: number } =
      domNode && domNode.getBoundingClientRect ? domNode.getBoundingClientRect() : null;

    let right: boolean =
      inputRect && inputRect.left > (document.body ? document.body.clientWidth / 2 : 0)
        ? true
        : false;

    return (
      <div className="ui-date-picker">
        <TextField
          inputRef={this.targetRef}
          icon="calendar"
          {...more}
          onBlur={this.formatLocalValue}
          onChange={this.handleChange}
          onFocus={this.openPopup}
          value={inputValue}

          // onBlur={this.closePopup}
        />
        <BodyPopup
          onRequestClose={this.closePopup}
          target={this.targetRef}
          open={open}
          customStyles={{
            right: '1.5rem',
            left: '1.5rem'
          }}
        >
          <div className={'ui-date-picker-popup' + (right ? ' right' : '')}>
            <Calendar
              events={value ? [{ from: value, color: 'primary', id: '' }] : undefined}
              onRequestSwitchMonth={this.handleSwitchMonth}
              onPick={this.handlePick}
              locale={locale || 'en'}
              month={month}
            />
          </div>
        </BodyPopup>
      </div>
    );
  }
}
