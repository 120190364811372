// @flow

import cn from 'classnames';
import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type PProps = {|
  variant?: 'heading' | 'subheading' | 'caption',
  children: React.Node
|};

// -------------------------------------------------------------------------------------------------

export default function P(props: PProps): React.Node {
  const { variant, children } = props;
  const Component = variant === 'heading' ? 'h4' : 'span';
  return <Component className={cn('ui-typo', { [variant || '']: variant })}>{children}</Component>;
}
