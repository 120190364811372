// @flow

import cn from 'classnames';
import * as React from 'react';

import type { FieldProps } from '../index';

// -------------------------------------------------------------------------------------------------

export type SelectValueType<T> = { value: T, label: React.Node };
export type SelectOptionsType<T> = Array<SelectValueType<T>>;

// -------------------------------------------------------------------------------------------------

export type SelectProps = {|
  ...FieldProps<any>,
  options: SelectOptionsType<any>
|};

type SelectState = {|
  isOpen: boolean
|};

// -------------------------------------------------------------------------------------------------

export default class Select extends React.PureComponent<SelectProps, SelectState> {
  activeRef: any = React.createRef();
  listRef: any = React.createRef();
  domRef: any = React.createRef();

  state = {
    isOpen: false
  };

  // // --------------------------------------------------------------------------------------------

  handleChange = (selection: SelectValueType<any>) => (e: SyntheticEvent<HTMLElement>): void => {
    e.preventDefault();
    if (this.state.isOpen) {
      this.setState({ isOpen: false }, () => {
        this.props.onChange({
          value: this.props.value === selection.value ? null : selection.value,
          name: this.props.name
        });
      });
    }
  };

  // // --------------------------------------------------------------------------------------------

  toggleOpen = (preset?: boolean) => (): void => {
    this.setState(state => ({
      isOpen: typeof preset === 'boolean' ? preset : !state.isOpen
    }));
  };

  // // --------------------------------------------------------------------------------------------

  windowToggle = (event: SyntheticEvent<HTMLElement>): void => {
    if (this.domRef.current && this.domRef.current.contains(event.target)) {
      return;
    }
    this.setState({ isOpen: false });
  };

  // // --------------------------------------------------------------------------------------------

  componentDidMount(): void {
    window.addEventListener('click', this.windowToggle);
  }

  // // --------------------------------------------------------------------------------------------

  componentWillUnmount() {
    window.removeEventListener('click', this.windowToggle, false);
  }

  // // --------------------------------------------------------------------------------------------

  componentDidUpdate(prevProps: SelectProps, prevState: SelectState): void {
    if (this.state.isOpen && this.activeRef && this.activeRef.current) {
      const y =
        this.activeRef.current.offsetTop -
        this.listRef.current.offsetHeight / 2 +
        this.activeRef.current.offsetHeight / 2;
      this.listRef.current.scrollTop = Math.max(y, 0);
    }
  }

  render(): React.Node {
    const { disabled, options, name, label, value, error } = this.props;
    const { isOpen } = this.state;

    const id = 'input-' + name + '-' + Math.ceil(Math.random() * 100);

    const renderLabel = function(item: SelectValueType<any>): React.Node {
      return item && item.label ? item.label : item ? item.value : '';
    };
    const buttonClassName = cn('btn dropdown-toggle', { 'is-invalid': error });

    const selectedItem = options.find(i => i.value === value);

    const dropdownContainer = cn('dropdown ui-select', {
      show: isOpen,
      'is-invalid': error
    });

    return (
      <div className="form-group ui-form-group" ref={this.domRef}>
        {' '}
        {/* form-group defined in ui theme */}
        {label && <label htmlFor={id}>{label}</label>}
        <div className={dropdownContainer}>
          {/* <input
            type="hidden"
            onFocus={this.toggleOpen(true)}
            onBlur={this.toggleOpen(false)}
            disabled={disabled}
            name={name}
            id={id}
          /> */}
          <div className="btn-group label">
            <button
              className={buttonClassName}
              onClick={this.toggleOpen()}
              aria-expanded={isOpen}
              data-toggle="dropdown"
              aria-haspopup="true"
              disabled={disabled}
              type="button"
              id={id}
            >
              <span>
                {selectedItem && renderLabel(selectedItem)}
                &nbsp;
              </span>
            </button>
          </div>
          <ul
            className={'dropdown-menu' + (isOpen ? ' show' : '')}
            ref={this.listRef}
            aria-labelledby="dLabel"
          >
            {options.map(item => (
              <button
                onClick={this.handleChange(item)}
                className={'dropdown-item' + (selectedItem === item ? ' active' : '')}
                ref={selectedItem === item ? this.activeRef : null}
                key={item.value}
              >
                {renderLabel(item)}
              </button>
            ))}
          </ul>
        </div>
        {error && <div className="invalid-feedback">{error.message}</div>}
      </div>
    );
  }
}
