// @flow

import * as React from 'react';

import I18n from '@serus/i18n';

export default function GeneralErrorMessage(props: {||}): React.Node {
  return (
    <center>
      <i className="icon-warning" style={{ fontSize: '2em' }} />
      <h4>
        <I18n id="title" d="Ooops!" />
      </h4>
      <h5>
        <I18n id="subtitle" d="Something went wrong." />
      </h5>
      <p className="mt-2">
        <I18n id="more" d="Please try again later." />
      </p>
    </center>
  );
}
