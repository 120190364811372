// @flow

import I18n from '@serus/i18n';
import cn from 'classnames';
import * as React from 'react';

import format from '../../../helpers/format';
import { tableCellDecorator, dateToNum } from '../Calendar';

import type { CalendarEvent } from '../Calendar';

// -------------------------------------------------------------------------------------------------

export type OccupancyTableProps = {|
  onRequestSwitchMonth?: Date => void,
  occupancy: Array<{
    car: { id: string, ecv: string, model: string, brand: string },
    events: Array<CalendarEvent>
  }>,
  onCellClick?: (string, date: Date, row: number, col: number) => void,
  loading?: boolean,
  locale: string,
  month: Date
|};

type OccupancyTableState = {| scrolled: boolean |};

// -------------------------------------------------------------------------------------------------

export default class OccupancyTable extends React.PureComponent<
  OccupancyTableProps,
  OccupancyTableState
> {
  tableWrapperRef = React.createRef<*>();

  state = {
    scrolled: false
  };

  // // --------------------------------------------------------------------------------------------

  scrollHandler = (e: SyntheticEvent<HTMLElement>): void => {
    if (e.currentTarget.scrollLeft === 0) {
      this.setState(state => (state.scrolled ? { scrolled: false } : null));
    } else if (e.currentTarget.scrollLeft > 0) {
      this.setState(state => (!state.scrolled ? { scrolled: true } : null));
    }
  };

  componentDidMount(): void {
    if (this.tableWrapperRef && this.tableWrapperRef.current) {
      this.tableWrapperRef.current.addEventListener('scroll', this.scrollHandler);
    }
  }

  componentDidUpdate(): void {
    if (this.tableWrapperRef && this.tableWrapperRef.current) {
      this.tableWrapperRef.current.addEventListener('scroll', this.scrollHandler);
    }
  }

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { locale, month, occupancy, loading } = this.props;
    const { scrolled } = this.state;

    const monthNameFormat = new Intl.DateTimeFormat(locale, {
      month: 'long',
      year: month.getFullYear() !== new Date().getFullYear() ? 'numeric' : undefined
    });

    const sundayIsFirst = false;
    const firstDay = new Date(new Date(month).setDate(1)).getDay() - (sundayIsFirst ? 1 : 0);

    const days = new Array(
      new Date(
        new Date(new Date(new Date(month).setUTCMonth(month.getUTCMonth() + 1)).setUTCDate(0))
      ).getUTCDate()
    ).fill(1);

    const dayNameFormat = new Intl.DateTimeFormat(locale, { weekday: 'short' });

    const headerDays = [];
    for (let i = 0; i < days.length; i++) {
      let d = new Date(new Date(month).setDate(1));
      d.setUTCDate(d.getUTCDate() + i);
      headerDays.push(d);
    }

    const rows = occupancy.map(row => ({
      events: tableCellDecorator(row.events),
      car: row.car
    }));

    const numDate = dateToNum(new Date(new Date(month).setDate(1)));

    return (
      <section className="ui-block-occupancy-table">
        <header>
          <nav>
            <i onClick={this.handleMonthSwitch} className="icon-arrow-left" data-dir="-1" />
            <h3>{monthNameFormat.format(month)}</h3>
            <i onClick={this.handleMonthSwitch} className="icon-arrow-right" data-dir="1" />
          </nav>
        </header>

        <div className="ui-block-occupancy-table-wrapper">
          <div />
          {rows.length > 0 ? (
            <React.Fragment>
              <div className={'ui-fixed-sidebar' + (scrolled ? ' scrolled' : '')}>
                <span>
                  <span>
                    <I18n id="ecvTitle" d="Ečv" />
                  </span>
                </span>
                <div className="cars-table">
                  {rows.map(row => (
                    <div key={row.car.id}>
                      <div>{`${row.car.brand} ${row.car.model}`}</div>
                      <div>{format.ecv(row.car.ecv)}</div>
                    </div>
                  ))}
                </div>
              </div>

              {/*  $FlowFixMe */}
              <div className="ui-table" ref={this.tableWrapperRef}>
                <table>
                  <thead>
                    <tr>
                      {headerDays.map(day => (
                        <th
                          title={day.toISOString().substr(0, 10)}
                          key={day.toISOString().substr(0, 10)}
                        >
                          {dayNameFormat.format(day)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={'tr-' + rowIndex}>
                        {days.map((day, colIndex) => {
                          let color = row.events[numDate + colIndex];
                          const tdClass: string = cn({
                            'rounded-right': color && color.mark === 'right',
                            'rounded-left': color && color.mark === 'left',
                            'start-day': (colIndex + firstDay) % 7 === 1,
                            // overlay: color && color.mark === 'both',
                            active: color
                          });
                          return (
                            <td
                              onClick={this.handleColClick}
                              data-id={color && color.id}
                              key={'td-' + colIndex}
                              data-col={colIndex}
                              data-row={rowIndex}
                              className={tdClass}
                            >
                              <span />
                              <span>{colIndex + 1}</span>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          ) : !loading ? (
            <div className="no-results">
              <I18n id="noMathingCars" d="No cars for search criteria found." />
            </div>
          ) : (
            <div className="loading">
              <I18n id="loading" d="Loading..." />
            </div>
          )}

          <div />
        </div>
      </section>
    );
  }

  handleColClick = (e: SyntheticEvent<HTMLElement>): void => {
    const date = new Date(this.props.month.getTime());
    const { id, col, row } = e.currentTarget.dataset;
    const { onCellClick } = this.props;
    date.setDate(parseInt(col, 10));
    onCellClick && onCellClick(id, date, parseInt(row, 10), parseInt(col, 10));
  };

  // // --------------------------------------------------------------------------------------------

  handleMonthSwitch = (e: SyntheticEvent<HTMLElement>) => {
    const dir: number = parseInt(e.currentTarget.dataset.dir, 10) || 0;
    dir && this.switch(dir);
  };

  // // --------------------------------------------------------------------------------------------

  switch = (dir: number): void => {
    const { onRequestSwitchMonth, month } = this.props;
    if (!onRequestSwitchMonth) {
      return;
    }
    if (!dir) {
      return;
    }
    let date = new Date(month);
    date.setMonth(date.getMonth() + dir);
    onRequestSwitchMonth(date);
  };
}
