// @flow
import I18n from '@serus/i18n';
import * as React from 'react';

import type { FieldError } from './types';

// -------------------------------------------------------------------------------------------------

export type ValidationMessageProps = { error: FieldError };

// -------------------------------------------------------------------------------------------------

export default class ValidationMessage extends React.PureComponent<ValidationMessageProps> {
  render(): React.Node {
    let options: void | $PropertyType<FieldError, 'options'> = undefined;

    const error: null | FieldError = this.props.error;

    if (error) {
      if (error.options && typeof error.options === 'object') {
        options = error.options;
      } else if (
        ['number', 'string'].indexOf(typeof error.options) > -1 ||
        error.options === null
      ) {
        options = { [error.code]: error.options };
      }

      return (
        error &&
        error.code && (
          <I18n
            {...options}
            id="validationMessage$"
            v={error.code}
            d={{
              digitsOnly: 'Pole mốže obsahovať iba číslice',
              email: 'Neplatný email',
              hasDigit: 'Hodnota musí obsahovať aspon jednu číslicu',
              hasUppercase: 'Hodnota musí obsahovať aspon jedno veľké písmeno',
              laterThan: 'Hodnota musí byť neskor ako {label}',
              max: 'Max {max}',
              maxLength: 'Najviac {maxLength} znakov',
              min: 'Min {min}',
              minLength: 'Najmenej {minLength} znakov',
              number: 'Neplatné číslo',
              phone: 'Neplatné telefónne číslo',
              required: 'Pole je povinné',
              zip: 'Neplatný formát PSČ'
            }}
          />
        )
      );
    }
    return null;
  }
}
