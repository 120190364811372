// @flow

import * as React from 'react';

import Loading from './Loading';

// -------------------------------------------------------------------------------------------------

export type LinearProgressProps = {|
  loading?: boolean,
  id: string
|};

type LinearProgressState = {|
  loading: boolean
|};

const STACK: { [string]: { parent: any, stack: Array<any> } } = {};

// // --------------------------------------------------------------------------------------------

function subscribe(id: string): number {
  if (!STACK[id]) {
    STACK[id] = { parent: null, stack: [] };
  }
  let i = STACK[id].stack.findIndex(i => !i);
  if (i === -1) {
    i = STACK[id].stack.push(true) - 1;
  } else {
    STACK[id].stack[i] = true;
  }
  STACK[id].parent &&
    !STACK[id].parent.state.loading &&
    STACK[id].parent.setState(() => ({
      loading: true
    }));
  return i;
}

// // ----------------------------------------------------------------------------------------------

function unsubscribe(id: string, index: number): number {
  if (STACK[id]) {
    delete STACK[id][index];
    let loading = STACK[id].stack.some(Boolean);
    if (STACK[id].parent && STACK[id].parent.state.loading && loading) {
      STACK[id].parent.setState(() => ({
        loading: false
      }));
    }
  }
  return -1;
}

// -------------------------------------------------------------------------------------------------

export default class LinearProgress extends React.PureComponent<
  LinearProgressProps,
  LinearProgressState
> {
  static unsubscribe = unsubscribe;
  static subscribe = subscribe;
  static Loading = Loading;

  constructor(props: LinearProgressProps): void {
    super(props);
    if (!STACK[this.props.id]) {
      STACK[this.props.id] = { parent: null, stack: [] };
    }
    this.state = {
      loading: STACK[props.id].stack.filter(Boolean).length > 0
    };
  }

  // // --------------------------------------------------------------------------------------------

  componentDidMount(): void {
    if (!STACK[this.props.id]) {
      STACK[this.props.id] = { parent: null, stack: [] };
    }
    STACK[this.props.id].parent = this;
  }

  // // --------------------------------------------------------------------------------------------

  componentWillUnmount(): void {
    STACK[this.props.id].parent = null;
  }

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    return this.state.loading || this.props.loading ? (
      <div className="ui-progress">
        <div className="indeterminate" />
      </div>
    ) : null;
  }
}
