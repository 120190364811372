// @flow

import cn from 'classnames';
import * as React from 'react';
import Link from 'react-router-dom/Link';

// -------------------------------------------------------------------------------------------------

export type AProps = {|
  children: React.Node,
  onClick?: () => void,
  className?: string,
  caption?: boolean,
  center?: boolean,
  blank?: boolean,
  title?: string,
  to?: string,
  style?: {}
|};

// -------------------------------------------------------------------------------------------------

export default function A(props: AProps): React.Node {
  const { to, children, title, caption, center, className, onClick, blank, style } = props;

  const classes = cn('ui-a', className || '', {
    caption,
    center
  });

  if (to && to.startsWith('/')) {
    return (
      <Link to={to} title={title} className={classes} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <a
      href={to || '#' + Math.random()}
      target={blank ? '_blank' : null}
      className={classes}
      onClick={onClick}
      title={title}
      style={style}
    >
      {children}
    </a>
  );
}
