// @flow

// const version = process.env.REACT_APP_VERSION || '0'; // version agnostic

const MAP: { [string]: string } = {
  APP_LOCALE: 'app_locale'
};

const persistedConfig = {
  map: MAP,

  get: function(itemName: $Values<typeof MAP>, def?: any): any {
    if (typeof itemName === 'undefined') {
      throw new Error('Unexpected persistent config param: ' + itemName);
    }
    let value = localStorage.getItem(itemName);
    return value ? JSON.parse(value) : def || null;
  },

  set: function(
    itemName: $Values<typeof MAP>,
    itemValue?: number | string | boolean | null | Object
  ): void {
    if (typeof itemName === 'undefined') {
      throw new Error('Unexpected persistent config param: ' + itemName);
    }
    setTimeout(() => {
      if (typeof itemValue === 'undefined') {
        localStorage.removeItem(itemName);
      } else {
        localStorage.setItem(itemName, JSON.stringify(itemValue));
      }
    });
  }
};

export default persistedConfig;
