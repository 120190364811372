// @flow

import I18n from '@serus/i18n';
import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export default function BigLoading(): React.Node {
  return (
    <div className="ui-big-loading">
      <div className="loading">
        <div className="bg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="#eeeeee" id="bg">
            <rect height="100" width="300" />
          </svg>
        </div>
        <div className="gear one">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="#f63d51" id="danger">
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z" />
          </svg>
        </div>
        <div className="gear two">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="#2a69fa" id="primary">
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z" />
          </svg>
        </div>
        <div className="gear three">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="#45dfb1" id="success">
            <path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6      c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3      l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9      c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z" />
          </svg>
        </div>
        <div className="lil-circle" />
        <svg className="blur-circle">
          <filter id="blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="0" />
          </filter>
          <circle
            filter="url(#blur)"
            fill="transparent"
            strokeWidth="40"
            stroke="white"
            cx="70"
            cy="70"
            r="66"
          />
        </svg>
      </div>
      <div className="text">
        <I18n id="loadiingDesc" d="Loading..." />
      </div>
    </div>
  );
}
