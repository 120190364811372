// @flow

import cn from 'classnames';
import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type DateHeaderProps = {|
  status?: 'danger' | 'success' | 'warning',
  allStatus?: boolean,
  label?: React.Node,
  value?: React.Node
|};

export default function Header(props: DateHeaderProps): React.Node {
  const { label, value, status, allStatus } = props;

  const labelClass = cn({ [status || '']: allStatus && status });
  const valueClass = cn({ [status || '']: status });

  return (
    <div className="ui-block-card-list-card-header">
      <div className={labelClass}>{label || null}</div>
      {typeof value !== undefined && <div className={valueClass}>{value}</div>}
    </div>
  );
}
