// @flow

import * as React from 'react';
import DOMServer from 'react-dom/server';

import apiImage from '../helpers/apiImage';

// -------------------------------------------------------------------------------------------------

export type ImgProps = {|
  alt: React.Node,
  fluid?: boolean,
  src: string
|};

// -------------------------------------------------------------------------------------------------

export default function Img(props: ImgProps): React.Node {
  const { src, fluid, alt } = props;
  return (
    <img
      alt={typeof alt === 'string' ? alt : DOMServer.renderToString(alt)}
      style={fluid ? { maxWidth: '100%' } : null}
      src={apiImage(src)}
    />
  );
}
