// @flow

import * as React from 'react';
import Redirect from 'react-router-dom/Redirect';

import AppConsumer from '../helpers/AppContext';

import type { AppContextValue } from '../helpers/AppContext';

// -------------------------------------------------------------------------------------------------

const COOKIE_NAME = process.env.REACT_APP_TOKEN_COOKIE_NAME || 'userToken';

// -------------------------------------------------------------------------------------------------
export default class Logout extends React.PureComponent<*> {
  update: null | (($Shape<AppContextValue>) => void) = null;

  constructor(props: *): void {
    super(props);

    let d = new Date();
    d.setTime(d.getTime() - 1000 * 60 * 60 * 24);
    let expires = d.toUTCString();

    document.cookie = `${COOKIE_NAME}=; expires=${expires}; path=/;`;
    document.cookie += `domain=${document.location.hostname}`;
  }

  componentDidMount(): void {
    this.update && this.update({ userToken: null });
  }

  render(): React.Node {
    return (
      <AppConsumer>
        {({ userToken, update }) => {
          this.update = update;
          return userToken ? null : <Redirect to="/login" />;
        }}
      </AppConsumer>
    );
  }
}
