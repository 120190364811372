// @flow

import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type IconProps = {
  name: string
};

// -------------------------------------------------------------------------------------------------

export default function Icon(props: IconProps): React.Node {
  return <i className={'icon-' + props.name} />;
}
