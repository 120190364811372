// @flow

import FullscreenContainer from './Fullscreen';
import CarDetailContainer from './CarDetail';
import CommonContainer from './Common';
import LoginContainer from './Login';
import FormContainer from './Form';

export default {
  FullscreenContainer,
  CarDetailContainer,
  CommonContainer,
  LoginContainer,
  FormContainer
};
