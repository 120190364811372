// @flow

import cn from 'classnames';
import * as React from 'react';

export type RowProps = {
  containerFluid?: boolean,
  spaceBottom?: boolean,
  children: React.Node
};

export type ColProps = {
  ...RowProps,
  cols?: 1 | 2 | 3 | 4,
  lgCols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  mdCols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  center?: boolean
};

const Grid = {
  Row: function GridRow(props: RowProps): React.Node {
    const { spaceBottom, containerFluid } = props;
    const className = cn('row', {
      'mb-4': spaceBottom,
      'ui-container-row-fluid': containerFluid
    });
    return <div className={className}>{props.children}</div>;
  },
  Col: function GridColumn(props: ColProps): React.Node {
    const { spaceBottom, cols, center, mdCols, lgCols, ...rest } = props;
    let size = 12 / parseInt(cols || 1, 10);
    if (size === 12) {
      size = 0;
    }
    const className = cn({
      ['col-' + size]: size,
      // $FlowFixMe
      [`col-lg-${12 / (lgCols || 1)}`]: lgCols,
      // $FlowFixMe
      [`col-md-${12 / (mdCols || 1)}`]: mdCols,
      'text-center': center,
      'mb-3': spaceBottom
    });
    return (
      <div className={className} {...rest}>
        {props.children}
      </div>
    );
  }
};

export default Grid;
