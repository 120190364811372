// @flow

import cn from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';

import Button from '../../Button';
import Progress from '../../Progress';

// -------------------------------------------------------------------------------------------------

export type NavBarProps = {|
  onRequestMenu?: () => void,
  isOpen?: boolean,
  history?: *
|};

// -------------------------------------------------------------------------------------------------

let sharedRef = document.createElement('div');

export type NavBarTitleProps = {|
  children: React.Node
|};

class NavBarTitle extends React.PureComponent<NavBarTitleProps> {
  render(): React.Node {
    return sharedRef && ReactDOM.createPortal(this.props.children, sharedRef);
  }
}

// -------------------------------------------------------------------------------------------------

class NavBar extends React.PureComponent<NavBarProps> {
  static Title = NavBarTitle;

  ref = React.createRef<*>();

  componentDidMount(): void {
    this.ref.current && this.ref.current.appendChild(sharedRef);
  }

  componentWillUnmount(): void {
    this.ref.current && this.ref.current.removeChild(sharedRef);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { onRequestMenu, isOpen } = this.props;

    return (
      <div className="navbar fixed-top navbar-light ui-block-navbar">
        <ul className="nav navbar-nav" style={{ marginRight: -50 }}>
          <Button onClick={this.goBack} color="light" shadow={false} lg>
            <i className="icon-arrow-left" />
          </Button>
        </ul>
        <ul className="nav navbar-nav navbar-logo mx-auto">
          <li className="nav-item ui-block-navbar-title" ref={this.ref} />
        </ul>
        <ul className="nav navbar-nav pull-sm-right ui-block-navbar-menu-button">
          <li className={cn('nav-item', { hidden: isOpen })}>
            <Button onClick={onRequestMenu} color="light" shadow={false} lg>
              <i className="icon-menu" />
            </Button>
          </li>
        </ul>
        <Progress id="appNavBar" />
      </div>
    );
  }
}

export default withRouter(NavBar);
