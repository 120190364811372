// @flow

import * as React from 'react';

export type AppContextValue = {|
  update: ($Shape<AppContextValue>) => void,
  userToken: string | null,
  apiUrl: string
|};

const defaultAppContext: AppContextValue = {
  update: () => undefined,
  userToken: null,
  apiUrl: ''
};

const AppContext: React.Context<AppContextValue> = React.createContext(defaultAppContext);

const { Provider, Consumer } = AppContext;

// -------------------------------------------------------------------------------------------------

export type AppProviderProps = {|
  defaultState?: $Shape<AppContextValue>,
  children: React.Node
|};

type AppProviderState = AppContextValue;

// -------------------------------------------------------------------------------------------------

export class AppProvider extends React.PureComponent<AppProviderProps, AppProviderState> {
  // // --------------------------------------------------------------------------------------------

  constructor(props: AppProviderProps): void {
    super(props);
    this.state = {
      ...defaultAppContext,
      update: this.handleUpdate.bind(this),
      ...(props.defaultState || {})
    };
  }

  // // --------------------------------------------------------------------------------------------

  handleUpdate = (value: $Shape<AppContextValue>): void => {
    this.setState(state => ({
      ...state,
      ...value,
      update: state.update
    }));
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { AppContext as AppContextType };

export default Consumer;
