// @flow

import I18n from '@serus/i18n';
import * as React from 'react';
import ReactDOM from 'react-dom';

import A from '../../A';
import Button from '../../Button';
import AppFooter from '../AppFooter';
import { installPWA, availableInstall } from '../../../index';

import logo from '../../../assets/images/favicon-32x32.png';

// -------------------------------------------------------------------------------------------------

export type MenuProps = {|
  menuItems: Array<{
    icon: string,
    label: React.Node,
    target: string,
    notifications?: number
  }>,
  onRequestClose?: () => void,
  open: boolean,
  domNode: any
|};

// -------------------------------------------------------------------------------------------------

export default class Menu extends React.PureComponent<MenuProps> {
  // // --------------------------------------------------------------------------------------------

  containerRef: RefObject = React.createRef<RefObject>();
  contentRef: RefObject = React.createRef<RefObject>();

  void(e: SyntheticEvent<HTMLElement>): void {
    e.preventDefault();
    e.stopPropagation();
  }

  handleTouch = (e: SyntheticEvent<any>) => {
    const content = this.contentRef.current;
    const wrapper = this.containerRef.current;
    if (!wrapper || !content) {
      return;
    }
    if (content.offsetHeight <= wrapper.offsetHeight) {
      e.preventDefault();
      return;
    }
    if (e.target === this.contentRef.current || content.contains(e.target)) {
      return;
    }
    e.preventDefault();
  };

  componentDidMount(): void {
    const body = document.body;
    const wrapper = this.containerRef.current;
    if (wrapper) {
      wrapper.addEventListener('touchmove', this.handleTouch, false);
    }
    if (body) {
      body.style.overflowY = this.props.open ? 'hidden' : 'auto';
    }
  }

  componentDidUpdate(): void {
    const body = document.body;
    const wrapper = this.containerRef.current;
    if (wrapper) {
      wrapper.addEventListener('touchmove', this.handleTouch, false);
    }
    if (body) {
      body.style.overflowY = this.props.open ? 'hidden' : 'auto';
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.style.overflowY = 'auto';
    }
  }

  handleInstall = (): void => {
    installPWA();
  };

  render(): React.Node {
    const { domNode, open, menuItems, onRequestClose } = this.props;
    return (
      open &&
      ReactDOM.createPortal(
        <div className="ui-block-menu" onClick={onRequestClose} ref={this.containerRef}>
          <div
            className="d-flex flex-column ui-block-menu-content"
            ref={this.contentRef}
            onClick={this.void}
          >
            <div className="ui-block-menu-header">
              <div className="ui-block-menu-avatar">
                <i className="icon-user" />
              </div>
              <div>
                <div className="ui-block-menu-username">Admin</div>
                <div className="ui-block-menu-brand">Kristofcars</div>
              </div>
            </div>
            <nav className="ui-block-menu-nav">
              {/* Menu list */}
              <ul className="nav flex-column">
                {menuItems.map((item, index) => {
                  let notifications = !!item.notifications && item.notifications > 0;
                  return (
                    <li className="nav-item" key={index}>
                      <A onClick={onRequestClose} className="nav-link" to={item.target}>
                        <i className={'icon-' + item.icon} />
                        {item.label}
                        {notifications && <span className="badge">{item.notifications}</span>}
                      </A>
                    </li>
                  );
                })}

                {/* Install button */}
                {availableInstall() && (
                  <li className="nav-item">
                    <A className="nav-link" onClick={this.handleInstall}>
                      <img className="installIcon" src={logo} alt="App logo" />
                      <I18n id="installApp" d="Install app" />
                    </A>
                  </li>
                )}
              </ul>
            </nav>

            <div className="mt-auto ui-block-menu-bottom">
              <A className="nav-link" to="/logout">
                <i className="icon-logout" />
                <I18n id="menuItem.logout" d="Logout" />
              </A>
              <AppFooter />
            </div>
          </div>
          <Button color="light" shadow={false} lg id="uiBlockMenuFakeButton">
            <i className="icon-menu" />
          </Button>
        </div>,
        domNode
      )
    );
  }
}
