// @flow

import cn from 'classnames';
import * as React from 'react';

import type { Color } from './index';

// -------------------------------------------------------------------------------------------------

export type ButtonProps = {
  /** Click event handler  */
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
  /** Button label  */
  children: React.Node,
  /** Disabled mark  */
  disabled?: boolean,
  /** Align content in vertical direction */
  vertical?: boolean,
  /** Outline style  */
  outline?: boolean,
  /** Display shadow  */
  shadow?: boolean,
  /** Expand button to parent width  */
  block?: boolean,
  /** Center button content without side padding */
  center?: boolean,
  /** render as <a ... */
  link?: boolean,
  /** choos a color "primary" "secondary" "danger" "warning" "success"  */
  color?: Color,
  /** apply btn-small styles */
  sm?: boolean,
  /** apply btn-large styles */
  lg?: boolean,
  /** display icon */
  icon?: string,
  /** add icon on the right */
  rightIcon?: string,
  /** rounded corners */
  rounded?: boolean,
  /** custom styles */
  style?: {}
};

// -------------------------------------------------------------------------------------------------

export type GroupProps = {|
  children: React.Node,
  shadow?: boolean,
  block?: boolean,
  lg?: boolean,
  sm?: boolean
|};

function Group(props: GroupProps): React.Node {
  const { lg, children, shadow, sm, block } = props;
  const className = cn('btn-group ui-btn-group', {
    'btn-group-block': block,
    'btn-group-lg': lg,
    'btn-group-sm': sm,
    'shadow-3': shadow
  });
  return <div className={className}>{children}</div>;
}

// -------------------------------------------------------------------------------------------------
export default class Button extends React.PureComponent<ButtonProps> {
  static defaultProps: ButtonProps = {
    onClick: () => undefined,
    color: 'primary',
    vertical: false,
    disabled: false,
    outline: false,
    shadow: false,
    block: false,
    children: '',
    link: false,
    sm: false,
    lg: false
  };

  static Group = Group;
  render(): React.Node {
    let {
      rightIcon,
      children,
      disabled,
      vertical,
      outline,
      rounded,
      onClick,
      shadow,
      block,
      color,
      icon,
      link,
      sm,
      lg,
      ...more
    } = this.props;
    shadow = this.props.shadow !== false && !link && !disabled;
    color = this.props.color || (link ? '' : 'primary');
    const className = cn('btn ui-button', {
      ['btn-' + color]: color && !outline,
      ['btn-outline-' + color]: outline,
      'btn-icon': icon && !children,
      'btn-vertical': vertical,
      'shadow-none': !shadow,
      'btn-block': block,
      'btn-link': link,
      'btn-lg': lg,
      'btn-sm': sm,
      disabled,
      rounded,
      shadow
    });

    return (
      <button className={className} disabled={disabled} onClick={onClick} {...more}>
        {icon || rightIcon ? (
          <React.Fragment>
            {icon && <i className={icon} />}
            <span>{children}</span>
            {rightIcon && <i className={rightIcon} />}
          </React.Fragment>
        ) : (
          children
        )}
      </button>
    );
  }
}
