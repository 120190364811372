// @flow

import cn from 'classnames';
import * as React from 'react';

import type { FieldProps } from '../index';

// -------------------------------------------------------------------------------------------------

export type TextFieldType = 'email' | 'password' | 'number' | 'text';

export type TextFieldProps = {
  // $FlowFixMe
  ...FieldProps<string>, // TODO: inspect flow problem
  type?: TextFieldType,
  multiline?: boolean,
  iconLeft?: boolean,
  noBorder?: boolean,
  icon?: React.Node,
  shadow?: boolean,
  inputRef?: any,
  rows?: number,
  lg?: boolean,
  sm?: boolean
};

// -------------------------------------------------------------------------------------------------

export default class TextField extends React.PureComponent<TextFieldProps> {
  // // --------------------------------------------------------------------------------------------

  inputRef: RefObject = React.createRef<RefObject>();

  handleChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    this.props.onChange &&
      this.props.onChange({
        value: e.currentTarget.value,
        name: this.props.name || '' // TODO: inspect flow problem
      });
  };

  // // --------------------------------------------------------------------------------------------

  handleIconClick = (): void => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const {
      multiline,
      disabled,
      iconLeft,
      noBorder,
      inputRef,
      shadow,
      label,
      error,
      value,
      type,
      name,
      icon,
      lg,
      sm,
      handleIconClick,
      ...more
    } = this.props;

    const className = cn('form-control ui-form-field', {
      'shadow-none': !shadow,
      'form-control-lg': lg,
      'form-control-sm': sm,
      'border-0': noBorder,
      'is-invalid': error,
      'has-icon': icon,
      disabled,
      shadow
    });

    const wrapperClassName = cn('input-wrapper', { 'icon-left': iconLeft });
    const groupClassName = cn('form-group ui-form-group', {
      'is-invalid-row': error
    });

    const id =
      'input-' + (type || 'text') + '-' + (name || '') + '-' + Math.ceil(Math.random() * 100);

    const Component = multiline ? 'textarea' : 'input';

    return (
      <div className={groupClassName}>
        {
          <label className={label ? '' : 'hidden'} aria-label={name} htmlFor={id}>
            {label || name}
          </label>
        }
        <div className={wrapperClassName} ref={inputRef || null}>
          <Component
            {...more}
            onChange={this.handleChange}
            className={className}
            value={value || ''}
            disabled={disabled}
            ref={this.inputRef}
            name={name}
            type={type}
            id={id}
          />
          {icon &&
            (typeof icon === 'string' ? (
              <i
                className={'ui-form-field-icon icon-' + icon}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={e => {
                  this.handleIconClick(e);
                  handleIconClick && handleIconClick(e);
                }}
              />
            ) : (
              <span
                className="ui-form-field-icon"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={e => {
                  this.handleIconClick(e);
                  handleIconClick && handleIconClick(e);
                }}
              >
                {icon}
              </span>
            ))}
          {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
      </div>
    );
  }
}
