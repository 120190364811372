// @flow

import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type FullscreenContainerProps = {|
  children: React.Node
|};

// -------------------------------------------------------------------------------------------------

export default function FullscreenContainer(props: FullscreenContainerProps): React.Node {
  return <div className={'ui-container-fullscreen'}>{props.children}</div>;
}
