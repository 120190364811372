// @flow

import cn from 'classnames';
import * as React from 'react';

import tableCellDecorator, { dateToNum } from './tableCellDecorator';
import type { StyledTableCell } from './tableCellDecorator';

// -------------------------------------------------------------------------------------------------

export type CalendarEvent = {
  color?: string,
  from: Date,
  id: string,
  to?: Date
};

export type CalendarProps = {|
  // selected: Array<Date | [Date, Date]>,
  onRequestSwitchMonth?: Date => void,
  events?: Array<CalendarEvent>,
  onClickEvent?: string => void,
  onPick?: Date => void,
  locale: string,
  month: Date
|};

type CalendarDay = {
  className: string,
  date: Date,
  id: string
};

// -------------------------------------------------------------------------------------------------

export default class Calendar extends React.PureComponent<CalendarProps> {
  swipeStart: number = 0;
  swipeLast: number = 0;

  // // --------------------------------------------------------------------------------------------

  handleMonthSwitch = (e: SyntheticEvent<HTMLElement>) => {
    const dir: number = parseInt(e.currentTarget.dataset.dir, 10) || 0;
    dir && this.switch(dir);
  };

  switch = (dir: number): void => {
    const { onRequestSwitchMonth, month } = this.props;
    if (!onRequestSwitchMonth) {
      return;
    }
    if (!dir) {
      return;
    }
    let date = new Date(month);
    date.setUTCDate(1);
    date.setUTCMonth(date.getUTCMonth() + dir);
    onRequestSwitchMonth(date);
  };

  // // --------------------------------------------------------------------------------------------

  static getWeekNumber(date: Date): number {
    let d: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart: Date = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo: number = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }

  // // --------------------------------------------------------------------------------------------

  touchEnd = (e: TouchEvent): void => {
    const delta = this.swipeStart - this.swipeLast;
    if (Math.abs(delta) > 50) {
      e.preventDefault();
      this.switch(delta > 0 ? 1 : -1);
    }
    this.swipeStart = 0;
    this.swipeLast = 0;
  };

  // // --------------------------------------------------------------------------------------------

  touchStart = (e: TouchEvent): void => {
    this.swipeStart = e.targetTouches[0].screenX;
    this.swipeLast = this.swipeStart;
  };

  // // --------------------------------------------------------------------------------------------

  touchMove = (e: TouchEvent): void => {
    this.swipeLast = e.targetTouches[0].screenX;
  };

  // // --------------------------------------------------------------------------------------------

  handleCLick = (e: SyntheticEvent<HTMLTableCellElement>): void => {
    e.preventDefault();
    let target = e.currentTarget;
    const onPick = this.props.onPick;

    if (target.dataset.id && this.props.onClickEvent) {
      this.props.onClickEvent(target.dataset.id);
    } else if (onPick) {
      const value = new Date();
      let date = (target.dataset.date || '').split('-').map(i => parseInt(i, 10));

      if (date.length !== 3) {
        return;
      }

      value.setUTCHours(0, 0, 0, 0);
      value.setUTCFullYear(date[0]);
      value.setUTCDate(date[2]);
      value.setUTCMonth(date[1] - 1);

      onPick(value);
    }
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { locale, month, events } = this.props;

    const dayNameFormat = new Intl.DateTimeFormat(locale, { weekday: 'short' });
    const monthNameFormat = new Intl.DateTimeFormat(locale, {
      month: 'long',
      year: month.getFullYear() !== new Date().getFullYear() ? 'numeric' : undefined
    });
    const dayFormat = new Intl.DateTimeFormat(locale, { day: 'numeric' });

    const FIRST_DAY = 1; // 1 monday, 0 sunday

    const start = new Date(month.getTime());
    start.setUTCFullYear(month.getUTCFullYear());
    start.setUTCHours(0, 0, 0, 0);
    start.setUTCDate(1);
    const s = start.getUTCDate() - start.getUTCDay() + FIRST_DAY;
    start.setUTCDate(s > 1 ? s - 7 : s);

    const end = new Date(month.getTime());
    end.setUTCFullYear(month.getUTCFullYear());
    end.setUTCHours(23, 59, 59, 999);
    end.setUTCMonth(end.getUTCMonth() + 1);
    end.setUTCDate(0);

    let timeDiff = new Date(end) - new Date(start);
    let d = (timeDiff / (1000 * 60 * 60 * 24)).toFixed(0);

    if (d > 41) {
      end.setUTCDate(0);
    }

    timeDiff = new Date(end) - new Date(start);
    d = (timeDiff / (1000 * 60 * 60 * 24)).toFixed(0);

    const a = (7 - (d % 7)) % 7;

    if (a) {
      end.setUTCDate(end.getUTCDate() + a);
    }

    const headerDays = [];
    for (let i = 0; i < 7; i++) {
      let d = new Date(start);
      d.setUTCDate(d.getUTCDate() + i);
      headerDays.push(d);
    }

    const count = (end - start) / 86400000;

    let colors: { [number]: StyledTableCell } = events ? tableCellDecorator(events) : {};

    const days: Array<Array<CalendarDay>> = [];
    const todayRef = new Date().toISOString().substr(0, 10);
    for (let i = 0, j = -1; i < count; i++) {
      if (i % 7 === 0) {
        days.push([]);
        j++;
      }
      let date = new Date(new Date(start).setUTCDate(start.getUTCDate() + i));
      let index = dateToNum(date);
      let color = colors[index] && colors[index].color ? `color-${colors[index].color}` : '';

      let today = todayRef === date.toISOString().substr(0, 10);
      days[j].push({
        date,
        className: cn({
          'rounded-right': colors[index] && colors[index].mark === 'right',
          'rounded-left': colors[index] && colors[index].mark === 'left',
          'rounded-both': colors[index] && colors[index].mark === 'both',
          inactive: date.getUTCMonth() !== month.getUTCMonth(),
          clickable: this.props.onPick,
          [color]: color,
          today
        }),
        id: colors[index] && colors[index].id
      });
    }

    return (
      <div
        className="ui-block-calendar"
        onTouchStart={this.touchStart}
        onTouchMove={this.touchMove}
        onTouchEnd={this.touchEnd}
      >
        <header>
          <nav>
            <i onClick={this.handleMonthSwitch} className="icon-arrow-left" data-dir="-1" />
            <h3>{monthNameFormat.format(month)}</h3>
            <i onClick={this.handleMonthSwitch} className="icon-arrow-right" data-dir="1" />
          </nav>

          <table>
            <thead>
              <tr>
                {headerDays.map(day => (
                  <th key={day.getUTCDay()}>{dayNameFormat.format(day)}</th>
                ))}
              </tr>
            </thead>
          </table>
        </header>

        <table
          onTouchStart={this.touchStart}
          onTouchMove={this.touchMove}
          onTouchEnd={this.touchEnd}
        >
          <tbody>
            {days.map(row => (
              <tr key={Calendar.getWeekNumber(row[0].date)}>
                {row.map(day => (
                  <td
                    data-date={day.date.toISOString().substr(0, 10)}
                    key={day.date.toISOString().substr(0, 10)}
                    onClick={this.handleCLick}
                    className={day.className}
                    data-id={day.id}
                  >
                    <span />
                    <span>{dayFormat.format(day.date)}</span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
