// @flow

import * as React from 'react';

export default function Wave(): React.Node {
  return (
    <div className="ui-progress-wave">
      <span />
      <span />
      <span />
    </div>
  );
}
