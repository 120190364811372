// @flow

import * as React from 'react';

import TextField from './TextField';

import type { OnChangeEvent } from './index';
import type { TextFieldProps } from './TextField';

// -------------------------------------------------------------------------------------------------

export type NumberProps = {
  ...TextFieldProps,
  onChange: (OnChangeEvent<number>) => void,
  value: number | null,
  int?: boolean
};

// -------------------------------------------------------------------------------------------------

export default class Number extends React.PureComponent<NumberProps> {
  appendSign: boolean = false;
  hideZero: boolean = false;
  comma: boolean = false;

  handleChange = (e: OnChangeEvent<string>): void => {
    const { int, onChange } = this.props;
    let val = e.value || '';
    if (!int) {
      this.comma = !!val && val.indexOf(',') > -1;
      val = val.replace(',', '.');
      this.hideZero = val.startsWith('.');
      this.appendSign = val === '.' || val.substr(-1) === '.';
    }

    const value = (int ? parseInt(val, 10) : parseFloat(val)) || (this.appendSign ? 0 : null);

    onChange &&
      onChange({
        value,
        name: e.name
      });
  };

  handleBlur = (e: SyntheticEvent<HTMLElement>): void => {
    const { onChange, int, value, name } = this.props;
    if (value && int && onChange) {
      onChange({ name: name || '', value: parseInt(value, 10) || null });
    }
  };

  render(): React.Node {
    let { onChange, value, ...passing } = this.props;
    onChange = this.handleChange;
    value = (value || '').toString();

    if (this.appendSign) {
      value += this.comma ? ',' : '.';
    } else if (this.comma) {
      value = value.replace('.', ',');
    }

    if (this.hideZero && value.length > 1) {
      value = value.substr(1);
    }

    delete passing.int;
    return (
      <TextField
        onBlur={this.handleBlur}
        onChange={onChange}
        value={value}
        type="number"
        {...passing}
      />
    );
  }
}
