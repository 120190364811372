// @flow

import * as React from 'react';

export default function pure(Component: React.ComponentType<*>): React.ComponentType<*> {
  class Pure extends React.Component<*> {
    shouldComponentUpdate(nextProps: *): boolean {
      return nextProps.error !== this.props.error || nextProps.value !== this.props.value; // TODO: enable errors
    }

    render(): React.Node {
      return <Component {...this.props} />;
    }
  }
  return Pure;
}
