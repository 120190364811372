// @flow

// TODO: add bugsnag, sentry etc

export type LogLevel = 'debug' | 'info' | 'error' | 'warning';

export default class Logger {
  static log(level: LogLevel, msg: string, additionalValue?: any): void {
    if (process.env.NODE_ENV !== 'production') {
      additionalValue
        ? console.log('LOGGER ' + level.toUpperCase(), msg, additionalValue) // eslint-disable-line
        : console.log('LOGGER ' + level.toUpperCase(), msg); // eslint-disable-line
    }
  }

  static error(msg: string, additionalValue?: any): void {
    Logger.log('error', msg, additionalValue);
  }

  static warning(msg: string, additionalValue?: any): void {
    Logger.log('warning', msg, additionalValue);
  }

  static info(msg: string, additionalValue?: any): void {
    Logger.log('info', msg, additionalValue);
  }

  static debug(msg: string, additionalValue?: any): void {
    Logger.log('debug', msg, additionalValue);
  }
}
