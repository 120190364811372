// @flow

import config from './config';
import PC from '../persistedConfig';

// -------------------------------------------------------------------------------------------------

export default function getInitLocale(): string {
  // $FlowFixMe
  const usrLang = navigator.userLanguage;
  let l = PC.get(PC.map.APP_LOCALE) || (navigator.language || usrLang || '');
  l = (typeof l === 'string' ? l : '').substr(0, 2).toLowerCase();
  return (
    (config.SUPPORTED_LOCALES.indexOf(l) > -1 && config.LOCALES_MAP[l]) || config.DEFAULT_LOCALE
  );
}

export { config };
