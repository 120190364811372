// @flow

import I18n from '@serus/i18n';
import cn from 'classnames';
import * as React from 'react';

import Header from './CarListCardHeader';
import format from '../../../helpers/format';

import type { CarsQuery_cars } from '../../../pages/_components/CarsQuery/__gql__/CarsQuery.js'; // eslint-disable-line

import placeholderImg from '../../../assets/images/car_placeholder.png';

// -------------------------------------------------------------------------------------------------

export type CarListCardProps = {|
  onClick: number => void,
  children?: React.Node,
  car: CarsQuery_cars // eslint-disable-line
|};

// -------------------------------------------------------------------------------------------------

export default class CarListCard extends React.PureComponent<CarListCardProps> {
  static Header = Header;

  handleClick = (): void => {
    this.props.onClick && this.props.onClick(parseInt(this.props.car.id, 10));
  };

  render(): React.Node {
    const { car, children } = this.props;

    const imageClass = cn('car-img', { 'rounded-0': children });

    const image = car.image && car.image.path;

    return (
      <div className="card shadow ui-block-card-list-card" onClick={this.handleClick}>
        {children || null}
        {/* header */}
        <div className="ui-block-card-list-image">
          <img className="car-placeholder" alt="Car placeholder" src={placeholderImg} />
          {image && (
            <div
              style={{
                backgroundImage: `url(${image})`
              }}
              className={imageClass}
            />
          )}
          <span className="ui-block-card-list-card-ecv">{format.ecv(car.ecv)}</span>
        </div>
        <div className="card-body ui-block-card-list-card-body">
          <dl className="ui-dl">
            <dt>
              <I18n id="brandLabel" d="Brand" />
            </dt>
            <dd>{car.brand}</dd>
            <dt>
              <I18n id="modelLabel" d="Model" />
            </dt>
            <dd>{car.model}</dd>
            <dt>
              <I18n id="year" d="Year" />
            </dt>
            <dd>{car.year.toString().substr(0, 4)}</dd>
          </dl>
        </div>
      </div>
    );
  }
}
