// @flow

import I18n from '@serus/i18n';
import * as React from 'react';

import blocks from '../_blocks';

// -------------------------------------------------------------------------------------------------

const { AppFooter } = blocks;

// -------------------------------------------------------------------------------------------------

export type LoginContainerProps = {|
  logoSrc: string,
  form: React.Node
|};

// -------------------------------------------------------------------------------------------------

export default function LoginContainer(props: LoginContainerProps): React.Node {
  const { form, logoSrc } = props;

  return (
    <div className="ui-container-login">
      <div className="ui-container-login-logo">
        <I18n id="loginLogogAlt" d="Logo">
          {alt => <img src={logoSrc} alt={alt} />}
        </I18n>
      </div>
      <div className="ui-container-login-form-container">
        {form}
        <div className="ui-container-login-footer">
          <AppFooter />
        </div>
      </div>
    </div>
  );
}
