// @flow

export default {
  ecv: function formatEcv(ecv: string): string {
    let val = ecv
      .toUpperCase()
      .replace(/[ -]/g, '')
      .substr(0, 7);
    return `${val.substr(0, 2)}-${val.substr(2, 3)} ${val.substr(5, 2)}`;
  }
};
