// @flow

import cn from 'classnames';
import * as React from 'react';
import RCSlider from 'rc-slider/lib/Slider';

import type { FieldProps } from '../index';

import 'rc-slider/assets/index.css';

// -------------------------------------------------------------------------------------------------

export type SliderProps = {|
  ...FieldProps<number>,
  min?: number,
  max?: number
|};

// -------------------------------------------------------------------------------------------------

export default class Slider extends React.PureComponent<SliderProps> {
  handleChange = (value: number): void => {
    const { disabled, name } = this.props;
    !disabled && this.props.onChange({ name, value });
  };

  render(): React.Node {
    const { label, name, min, max, value, disabled } = this.props;

    const id = 'input-' + name + '-' + Math.ceil(Math.random() * 100);

    const className = cn('form-group ui-form-group ui-slider-wrapper', {
      disabled
    }); // ui-form-group - theme class

    return (
      <div className={className}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className={'ui-slider-value' + (label ? ' has-label' : '')}>{value}</div>
        <RCSlider
          onChange={this.handleChange}
          className="ui-slider"
          max={max || 100}
          min={min || 0}
          value={value}
          step={1}
        />
      </div>
    );
  }
}
