// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';

import { Wave } from '../../Progress';
import { withRouter } from 'react-router-dom';

// -------------------------------------------------------------------------------------------------

export type MessageBoxProps = {|
  actions?: Array<React.Node>,
  requestClose?: () => void,
  children?: React.Node,
  title?: React.Node,
  warning?: boolean,
  loading?: boolean,
  modal?: boolean,
  open?: boolean
|};

type MessageBoxState = {};

// -------------------------------------------------------------------------------------------------

class MessageBox extends React.PureComponent<MessageBoxProps, MessageBoxState> {
  state = {};
  mounted: boolean = false;

  void(e: SyntheticEvent<*>): void {
    e.preventDefault();
    e.stopPropagation();
  }

  componentDidMount(): void {
    this.mounted = true;
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  static getDerivedStateFromProps(nextProps: MessageBoxProps): null {
    const element = document.getElementById('app-root');
    const has =
      element &&
      element.classList &&
      // $FlowFixMe
      element.classList.value &&
      // $FlowFixMe
      element.classList.value.indexOf('fixed') > -1;

    if (element && nextProps.modal && has !== nextProps.open) {
      element.classList.toggle('fixed');
    }
    return null;
  }

  render(): React.Node {
    const { children, actions, warning, loading, modal, title, open } = this.props;

    const content = (
      <div className="ui-messagebox" onClick={this.void}>
        {title && <h5>{title}</h5>}
        <div className="content">
          {warning && <i className="icon-warning" />}
          {loading && <Wave />}
          {children}
        </div>
        {actions && <div className="actions">{actions}</div>}
      </div>
    );

    const body = modal && document.body;

    if (body) {
      return ReactDOM.createPortal(
        <div
          className={'ui-messagebox-modal' + (open ? ' show' : '')}
          onClick={this.props.requestClose}
        >
          {content}
        </div>,
        body
      );
    }

    return content;
  }
}

MessageBox = withRouter(MessageBox);

export default MessageBox;
