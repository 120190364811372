// @flow

import * as React from 'react';

import I18n from '@serus/i18n';
import Redirect from 'react-router-dom/Redirect';
import Form from '../helpers/Form/FormProvider';
import AppConsumer from '../helpers/AppContext';

import ThemeProvider, { SubmitButton, containers, TextField, Message } from '../UI';

import type { ValidationRules } from '../helpers/Form';

import logo from '../assets/images/logo.png';

// -------------------------------------------------------------------------------------------------

const COOKIE_NAME = process.env.REACT_APP_TOKEN_COOKIE_NAME || 'userToken';
const API = process.env.REACT_APP_SERVER || '';

const { LoginContainer } = containers;

export type LoginFormDefiniton = {|
  password: string,
  name: string
|};

export type LoginPageProps = {||};

type LoginPageState = {|
  loading: boolean
|};

// -------------------------------------------------------------------------------------------------

export default class LoginPage extends React.PureComponent<LoginPageProps, LoginPageState> {
  state = {
    loading: false
  };

  // static +defaultValue: LoginFormDefiniton = {
  //   password: 'test',
  //   name: 'Andrej'
  // };

  static +validationRules: ValidationRules<LoginFormDefiniton> = {
    password: { required: true },
    name: { required: true }
  };

  // // --------------------------------------------------------------------------------------------

  handleSubmit = (updateContext: any => void) => (data: LoginFormDefiniton) => {
    const url =
      API.replace('http://localhost', 'http://' + document.location.hostname) + '/auth/login';

    this.setState({ loading: true }, _ => {
      LoginPage.callFetch(url, data).then(data => {
        this.setState({ loading: false }, _ => {
          if (data.access_token) {
            this.setUser(updateContext, data);
          }
        });
      });
    });
  };

  static callFetch(url: string, data: *): Promise<*> {
    return fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => res.json());
  }

  setUser = (updateContext: any => void, data: *) => {
    var d = new Date();
    d.setTime(d.getTime() + data.expires_in * 1000);
    document.cookie =
      `${COOKIE_NAME}=${data.access_token}` +
      `; expires=${d.toUTCString()}` +
      `; path=/; domain=${document.location.hostname}`;

    updateContext({ userToken: data.access_token });
  };

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    return (
      <AppConsumer>
        {({ userToken, update }) =>
          userToken ? (
            <Redirect to="/" />
          ) : (
            <ThemeProvider>
              <LoginContainer
                logoSrc={logo}
                form={
                  <Form
                    validationRules={LoginPage.validationRules}
                    onSubmit={this.handleSubmit(update)}
                  >
                    {/*  defaultValue={LoginPage.defaultValue}*/}

                    <Form.Field
                      autoComplete="new-name"
                      component={TextField}
                      placeholder=""
                      type="text"
                      name="name"
                      icon="user"
                      iconLeft
                      shadow
                      lg
                    />

                    <Form.Field
                      autoComplete="new-password"
                      component={TextField}
                      name="password"
                      type="password"
                      icon="password"
                      placeholder=""
                      iconLeft
                      shadow
                      lg
                    />

                    <SubmitButton lg disabled={this.state.loading}>
                      <I18n id="loginbutton" d="Login" />
                    </SubmitButton>

                    {/* <A to="/forgotten-password" center caption>
                      <I18n
                        id="forgottenPassword"
                        d="Did you forget password?"
                      />
                    </A> */}

                    {false && (
                      <Message type="danger">
                        <I18n id="loginFailed" d="Oops! Authorization failed, please try again!" />
                      </Message>
                    )}
                  </Form>
                }
              />
            </ThemeProvider>
          )
        }
      </AppConsumer>
    );
  }
}
