// @flow

import * as React from 'react';

import './ui.scss';

// -------------------------------------------------------------------------------------------------

export type ThemeProviderProps = {|
  children: React.Node
|};

// -------------------------------------------------------------------------------------------------

export default class ThemeProvider extends React.PureComponent<ThemeProviderProps> {
  render(): React.Node {
    return this.props.children;
  }
}
