// @flow

import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type FormContainerProps = {|
  children: React.Node
|};

// -------------------------------------------------------------------------------------------------

function Footer(props: { children: React.Node }): React.Node {
  return <div className="ui-container-form-footer">{props.children}</div>;
}

// -------------------------------------------------------------------------------------------------

export type SectionHeaderProps = {|
  title: React.Node,
  icon: string
|};

// -------------------------------------------------------------------------------------------------

function SectionHeader(props: SectionHeaderProps): React.Node {
  const { icon, title } = props;

  return (
    <div className="section-header">
      <i className={`icon-${icon}`} />
      {title}
    </div>
  );
}

// -------------------------------------------------------------------------------------------------

class FormContainer extends React.PureComponent<FormContainerProps> {
  static SectionHeader = SectionHeader;
  static Footer = Footer;

  render(): React.Node {
    const { children } = this.props;

    return <div className="ui-container-form">{children}</div>;
  }
}

export default FormContainer;
