// @flow

import * as React from 'react';

import type { FieldProps } from '../index';

// -------------------------------------------------------------------------------------------------

export type CheckBoxProps = {|
  ...FieldProps<boolean>,
  options: Array<{ value: any, label: React.Node }>
|};

// -------------------------------------------------------------------------------------------------

export default class Radio extends React.PureComponent<CheckBoxProps> {
  // // --------------------------------------------------------------------------------------------

  handleChange = (e: SyntheticEvent<HTMLElement>): void => {
    e.preventDefault();
    let ev = {
      name: this.props.name,
      // $FlowFixMe
      value: this.props.options[e.currentTarget.dataset.index * 1].value
    };

    this.props.onChange(ev);
  };

  void(): void {}

  // // --------------------------------------------------------------------------------------------

  render(): React.Node {
    const { label, value, name, options, ...more } = this.props; // eslint-disable-line

    return (
      <div className="form-group ui-form-group ui-radio">
        <label>{label}</label>
        <div className="ui-radio-wrapper">
          {options.map((option, key: number) => {
            let id = 'radio_' + name + '_' + option.value;

            return (
              <label
                className={value === option.value ? 'checked' : ''}
                data-val={value === option.value ? '1' : '0'}
                onClick={this.handleChange}
                data-index={key}
                htmlFor={id}
                key={key}
              >
                <input
                  {...more}
                  checked={value === option.value}
                  onFocus={this.handleChange}
                  onChange={this.void}
                  value={'-' + key}
                  data-index={key}
                  type="radio"
                  name={name}
                  id={id}
                />
                <span className="sign" />
                <span className="text">{option.label}</span>
              </label>
            );
          })}
        </div>
      </div>
    );
  }
}
