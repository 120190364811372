// @flow
import { isValidNumber } from 'libphonenumber-js';

// TODO: move to format helper
let stripHtml = text => {
  return text.replace(/(<([^>]+)>)/gi, '');
};

// $FlowFixMe
const rules: { [string]: (any, any, any) => boolean } = {
  email: (value: string): boolean =>
    !value ||
    /^(([^#&$+^<>()[\]\\.,;:\s@"]+(\.[^#&$+^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value.trim()
    ),
  required: (value: any): boolean =>
    !(
      (typeof value === 'number' && isNaN(value)) ||
      typeof value === 'undefined' ||
      value === null ||
      value === ''
    ),
  number: (value: string | number): boolean =>
    (typeof value === 'string' && /ˆ[0-9]+$/.test(value)) || !isNaN(value),
  maxLength: (value: string, param?: number): boolean =>
    typeof value === 'string' && stripHtml(value).length <= (param || 0),
  minLength: (value: string, param?: number): boolean =>
    typeof value === 'string' && value.length >= (param || 0),
  iban: (value: string): boolean =>
    !value || /^(SK|CZ)\d{2}\s?([0-9a-zA-Z]{4}\s?){5}$/.test(value.trim()),
  max: (value: number, param?: number): boolean =>
    typeof value !== 'number' || value <= (param || 0),
  min: (value: number, param?: number): boolean =>
    typeof value !== 'number' || value >= (param || 0),
  phone: (value: string): boolean =>
    !value || isValidNumber({ phone: value.trim(), country: 'SK' }),
  vatNumber: (value: string): boolean => !value || /^[a-zA-Z]{2}[0-9]{8,10}$/.test(value.trim()),
  htmlRequired: (value: string): boolean => stripHtml(value).trim().length > 0,
  bankCode: (value: string): boolean => !value || /^[A-Z0-9]{4}$/.test(value.trim()),
  countryCode: (value: string): boolean => !value || /^[A-Z]{2}$/.test(value.trim()),
  businessId: (value: string): boolean => !value || /^[0-9]{8}$/.test(value.trim()),
  zip: (value: string): boolean => !value || /^\d{3}[ ]?\d{2}$/.test(value.trim()),
  digitsOnly: (value: string | number): boolean =>
    !value || /^[0-9.,]+$/.test(typeof value === 'string' ? value.trim() : value.toString()),
  taxId: (value: string): boolean => !value || /^[0-9]{10}$/.test(value.trim()),
  hasUppercase: (value: string) => !value || /[A-Z]+/.test(value),
  hasDigit: (value: string) => !value || /[0-9]+/.test(value),
  laterThan: (value: Date, options: { name: 'string' }, values) => {
    let isInvalid =
      !value ||
      !(value instanceof Date) ||
      !options ||
      !options.name ||
      !values[options.name] ||
      !(values[options.name] instanceof Date);
    if (isInvalid) {
      return false;
    }
    return value >= values[options.name];
  }
};

// -------------------------------------------------------------------------------------------------

export type ValidationName = $Keys<typeof rules>;
export type ValidationRules<D> = { [$Keys<D>]: { [ValidationName]: any } };

// -------------------------------------------------------------------------------------------------

export default function validate(
  type: ValidationName,
  value: string | number | boolean | null,
  options?: any,
  values?: any
): boolean {
  if (rules.hasOwnProperty(type) && typeof rules[type] === 'function') {
    return rules[type](value, options, values);
  }
  throw new Error('Invalid rule type: ' + type);
}
