// @flow

import * as React from 'react';

import type { FieldProps } from '../index';

// -------------------------------------------------------------------------------------------------

export type CheckBoxProps = {|
  ...FieldProps<boolean>,
  shadow?: boolean,
  confirmCheck?: () => booleam
|};

// -------------------------------------------------------------------------------------------------

export default class CheckBox extends React.PureComponent<CheckBoxProps> {
  onChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    if (!this.props.value && this.props.confirmCheck) {
      if (!this.props.confirmCheck()) {
        return;
      }
    }
    this.props.onChange({ name: this.props.name, value: !this.props.value });
  };

  render(): React.Node {
    // eslint-disable-next-line no-unused-vars
    const { label, value, name, confirmCheck, ...more } = this.props;

    return (
      <div className="form-group ui-form-group ui-checkbox">
        <label>
          <input
            {...more}
            value={value ? 'true' : 'false'}
            onChange={this.onChange}
            type="checkbox"
            checked={value}
            name={name}
          />
          <span className="cr cr-icon">
            {value ? (
              <React.Fragment>&#x2714;</React.Fragment>
            ) : (
              <React.Fragment>&nbsp;</React.Fragment>
            )}
            {/* <i className="cr-icon fas fa-check" /> */}
          </span>
          <span className="text">{label}</span>
        </label>
      </div>
    );
  }
}
