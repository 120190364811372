// @flow

import * as React from 'react';

import A from '../A';
import apiImage from '../../helpers/apiImage';

import placeholder from '../../assets/images/car_placeholder.png';

// -------------------------------------------------------------------------------------------------

export type CarDetailContainerProps = {|
  children: React.Node,
  id: number | string,
  image?: string,
  ecv: string
|};

// -------------------------------------------------------------------------------------------------

export default class CarDetailContainer extends React.PureComponent<CarDetailContainerProps> {
  render(): React.Node {
    const { image, id, children, ecv } = this.props;
    return (
      <article className="ui-container ui-container-car-detail">
        <div className="ui-container-car-detail-image">
          <img className="placeholder" src={placeholder} alt="Car" />
          {image && (
            <div
              style={{
                backgroundImage: `url(${apiImage(image)})`
              }}
              className="carPhoto"
            />
          )}
          <A className="bookIcon" to={'/new-booking/' + ecv}>
            <i className="icon-calendar" />
          </A>

          <A className="editIcon" to={'/edit-car/' + id}>
            <i className="icon-edit" />
          </A>
        </div>
        <div className="ui-container-car-detail-content">{children}</div>
      </article>
    );
  }
}
