// @flow

import React from 'react';
import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import { hydrate, render } from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

// -------------------------------------------------------------------------------------------------

const bugsnagClient =
  process.env.NODE_ENV === 'production' && bugsnag(process.env.REACT_APP_BUGSNAG_ID);
const ErrorBoundary = bugsnagClient ? bugsnagClient.use(createPlugin(React)) : null;

// -------------------------------------------------------------------------------------------------
// PWA install

let ASK_INSTALL_EVENT;

window.addEventListener('beforeinstallprompt', e => {
  e.preventDefault();
  ASK_INSTALL_EVENT = e;
});

window.addEventListener('appinstalled', e => {
  bugsnagClient && bugsnagClient.notify('app installed');
});

export function installPWA(): void {
  if (ASK_INSTALL_EVENT && ASK_INSTALL_EVENT.prompt) {
    ASK_INSTALL_EVENT.prompt().then(_ => {
      ASK_INSTALL_EVENT = null;
    });
  }
}

export function availableInstall(): boolean {
  return !!ASK_INSTALL_EVENT && ASK_INSTALL_EVENT.hasOwnProperty('prompt');
}

// -------------------------------------------------------------------------------------------------

window.version = process.env.REACT_APP_VERSION;

export const domRoot: ?Element = document.getElementById('app-root');

let MyApp = App;

// Wrap app to err helper
if (ErrorBoundary) {
  MyApp = function ProductionApp() {
    return (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    );
  };
}

// -------------------------------------------------------------------------------------------------

if (domRoot && domRoot.hasChildNodes()) {
  hydrate(<MyApp />, domRoot);
} else if (domRoot) {
  render(<MyApp />, domRoot);
}

serviceWorker.register();
