// @flow

import cn from 'classnames';
import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type LiProps = {|
  children: any => React.Node,
  spaceBetween?: boolean,
  each: Array<*>,
  style?: {}
|};

export default class Li extends React.PureComponent<LiProps> {
  render(): React.Node {
    const { each, spaceBetween, style } = this.props;
    const liClass = cn({ 'space-between': spaceBetween });
    return (
      <ul className="ui-ul">
        {each.map((item, key: number) => (
          <li className={liClass} key={key} style={style}>
            {this.props.children(item)}
          </li>
        ))}
      </ul>
    );
  }
}
