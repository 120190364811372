// @flow

import * as React from 'react';

import LinearProgress from './Linear';

export type AppBarLoadingProps = {|
  loading: boolean,
  id: string
|};

class Loading extends React.PureComponent<AppBarLoadingProps> {
  static displayName = 'AppBarLoading';
  handler: number = -1;

  constructor(props: AppBarLoadingProps): void {
    super(props);
    if (props.loading) {
      this.handler = LinearProgress.subscribe(props.id);
    }
  }

  componentDidUpdate(): void {
    if (this.props.loading) {
      this.handler = LinearProgress.subscribe(this.props.id);
    } else if (this.handler > -1) {
      this.handler = LinearProgress.unsubscribe(this.props.id, this.handler);
    }
  }

  componentWillUnmount(): void {
    if (this.handler > -1) {
      this.handler = LinearProgress.unsubscribe(this.props.id, this.handler);
    }
  }

  render(): React.Node {
    return null;
  }
}

export default Loading;
