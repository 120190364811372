// @flow

import * as React from 'react';

// -------------------------------------------------------------------------------------------------

export type FormProps = {|
  onSubmit?: () => void,
  children: React.Node
|};

// -------------------------------------------------------------------------------------------------

export default class Form extends React.PureComponent<FormProps> {
  handleSubmit = (e: SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.props.onSubmit && this.props.onSubmit();
  };

  render(): React.Node {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.props.children}
        <button style={{ display: 'none', opacity: '0', visibility: 'hidden' }} type="submit" />
      </form>
    );
  }
}
